import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DailyCategoryInventoryDTO, LoginResponse, SetDailyInventoryCategoriesDTO } from '../../../../../models/view-models';
import { LoadingService } from '../../../service-pay/common/components/loading/loading.service';
import { CategoriesService } from '../../../../../services/categories.service';
import { CategoryInventoryService } from '../../../../../services/category-inventory.service';
import { AuthenticationService } from '../../../../../interceptors/authentication.service';

@Component({
  selector: 'app-category-inventory-form',
  templateUrl: './category-inventory-form.component.html',
  styleUrls: ['./category-inventory-form.component.scss']
})
export class CategoryInventoryFormComponent implements OnInit, AfterViewInit {

  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren('categoryInventoryInput') categoryInventoryInputs: QueryList<ElementRef>;

  dailyCategoriesInventory: DailyCategoryInventoryDTO[] = [];

  insertionDate: Date;
  minDate: Date;
  maxDate: Date;

  userInfo: LoginResponse;

  isEdition: boolean= false;

  constructor(private categoryInventoryService: CategoryInventoryService,
    private authService: AuthenticationService,
    private loading: LoadingService,
    private categoryService: CategoriesService,
    private cdr: ChangeDetectorRef) {
    this.userInfo = this.authService.SessionUserValue();

    this.insertionDate = new Date();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.insertionDate.setDate(this.insertionDate.getDate() + 1);
    this.maxDate.setDate(this.insertionDate.getDate() + 30);
  }

  ngOnInit(): void {
    this.getInventoryCategories();
  }

  ngAfterViewInit(): void {
    this.focusFirstInput();
  }

  getInventoryCategories() {
    this.loading.show();
    this.categoryService.getDailyInventoryCategoriesFromDate(this.insertionDate)
      .subscribe((response: DailyCategoryInventoryDTO[]) => {
        if (response) {
          this.dailyCategoriesInventory = response;
          this.isEdition = this.dailyCategoriesInventory.some(item => item.Quantity > 0);
          this.cdr.detectChanges();
          this.focusFirstInput();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
        console.log("Error al obtener lista de categorías de inventario: ", err);
      });
  }

  private focusFirstInput() {
    if (this.categoryInventoryInputs && this.categoryInventoryInputs.first) {
      this.categoryInventoryInputs.first.nativeElement.focus();
      this.categoryInventoryInputs.first.nativeElement.select();
    }
  }

  isValidForm(): boolean {
    for (let item of this.dailyCategoriesInventory) {
      if (item.Quantity <= 0)
        return false;
    }
    return true;
  }

  selectDate(date: Date) {
    this.insertionDate = date;
    this.getInventoryCategories();
  }

  onSubmit() {
    if (!this.isValidForm()) return;

    if (!this.isEdition) {
      this.save();
    } else {
      this.update();
    }
  }

  save() {
    this.loading.show();
    const setDailyInventoryCategoriesDto: SetDailyInventoryCategoriesDTO = {
      InsertionDate: this.insertionDate,
      InventoryCategories: this.dailyCategoriesInventory.map(item => ({
        UserId: this.userInfo.UUID,
        CategoryId: item.Category.UUID,
        Quantity: item.Quantity
      }))
    };
    this.categoryInventoryService.InsertDateInventory(setDailyInventoryCategoriesDto)
      .subscribe((response: boolean) => {
        this.onSave.emit(response);
      }, (err) => {
        console.log("Error al guardar registros de inventario de categorías", err);
      }); 
  }

  update() {
    this.loading.show();
    const setDailyInventoryCategoriesDto: SetDailyInventoryCategoriesDTO = {
      InsertionDate: this.insertionDate,
      InventoryCategories: this.dailyCategoriesInventory.map(item => ({
        UserId: this.userInfo.UUID,
        CategoryId: item.Category.UUID,
        Quantity: item.Quantity
      }))
    };
    this.categoryInventoryService.updateInventoryFromDate(setDailyInventoryCategoriesDto)
      .subscribe((response: boolean) => {
        this.onSave.emit(response);
      }, (err) => {
        console.log("Error al guardar registros de inventario de categorías", err);
      });
  }

  cancel() {
    this.onSave.emit(false);
  }

}
