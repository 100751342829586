import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginResponse } from '../service-pay/models/Login';
import { ShiftService } from '../../../services/shift.service';
import { DecimalPipe } from '@angular/common';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { LoadingService } from '../service-pay/common/components/loading/loading.service';
import { ActionPermissionValidatorService } from '../../../services/action-permission-validator.service';
import { LocalData } from '../../../models/LocalData';
import { CategoryVM, DailyCategoryInventoryDTO, InsertDailyCategoryInventoryDTO, ShiftStarterDto, ShiftVM } from '../../../models/view-models';
import { CategoriesService } from '../../../services/categories.service';

@Component({
  selector: 'app-shift-starter',
  templateUrl: './shift-starter.component.html',
  styleUrls: ['./shift-starter.component.scss']
})
export class ShiftStarterComponent implements OnInit {

  cashForm: FormGroup;
  @ViewChild("cash") cash: ElementRef;
  public dialogOpen: boolean = false;

  @Output() onDialogChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUserIsAdmin: EventEmitter<boolean> = new EventEmitter<boolean>();

  userInfo: LoginResponse;
  username: string = "";
  createButtonDisabled: boolean = false;

  dailyCategoriesInventory: DailyCategoryInventoryDTO[] = [];

  constructor(private shiftService: ShiftService,
    private decimalPipe: DecimalPipe,
    private authService: AuthenticationService,
    private loading: LoadingService,
    private actionValidatorService: ActionPermissionValidatorService,
    private fb: FormBuilder,
  private categoryService:CategoriesService) { }

  ngOnInit(): void {
    this.userInfo = this.authService.SessionUserValue();
    this.username = this.userInfo.User;
    this.initForm();
    this.createButtonDisabled = this.actionValidatorService.createShift();
    this.loadShiftInfo();
    this.getInventoryCategories();
  }

  initForm() {
    this.cashForm = this.fb.group({
      Cash: [this.setFormatNumber(0), [Validators.required, Validators.min(0)]]
    });
  }

  loadShiftInfo() {
    if (this.userInfo.Rol !== LocalData.ADMIN_ROL) {
      this.loading.show();
      this.shiftService.getByUserId(this.userInfo.UUID)
        .subscribe((response: ShiftVM) => {
          if (response) {
            localStorage.setItem(LocalData.ACTIVE_SHIFT, JSON.stringify(response));
            this.dialogOpen = false;
          } else {
            this.dialogOpen = true;
            setTimeout(() => {
              this.cash.nativeElement.focus();
              this.cash.nativeElement.select();
            }, 0);
          }
          this.loading.hide();
          this.onDialogChange.emit(this.dialogOpen);
        }, (err) => {
          this.loading.hide();
        });
    } else {
      this.dialogOpen = false;
      this.onUserIsAdmin.emit(true);
    }
  }

  getInventoryCategories() {
    this.loading.show();
    this.categoryService.getDailyInventoryCategories(true)
      .subscribe((response: DailyCategoryInventoryDTO[]) => {
        if (response) {
          this.dailyCategoriesInventory = response;
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
        console.log("Error al obtener lista de categorías de inventario: ", err);
      });
  }

  public open() {
    this.dialogOpen = true;
    this.onDialogChange.emit(this.dialogOpen);
  }

  isValidForm(): boolean { return this.cashForm.valid; }

  public onAccept() {
    if (this.isValidForm()) {
      this.loading.show();
      const shiftStarter: ShiftStarterDto = {
        Shift: Object.assign({
          UserId: this.userInfo.UUID,
          Username: this.userInfo.User,
          Active: true
        }, this.cashForm.value),
        InventoryCategories: this.dailyCategoriesInventory.filter(x=>x.AllowEdition == true)
          .map(item => ({
          UserId: this.userInfo.UUID,
          CategoryId: item.Category.UUID,
          Quantity: item.Quantity
        }))
      };

      this.shiftService.startShift(shiftStarter)
        .subscribe((response: ShiftVM) => {
          if (response) {
            localStorage.setItem(LocalData.ACTIVE_SHIFT, JSON.stringify(response));
            this.dialogOpen = false;
            this.onDialogChange.emit(this.dialogOpen);
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });;
    }
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  logout() {
    this.authService.logout();
  }

}
