import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CategoryVM } from '../../../models/view-models';
import { CategoriesService } from '../../../services/categories.service';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss']
})
export class CategorySelectorComponent implements OnInit {

  @Input("disableInventoryCategories") disableInventoryCategories: boolean= false;

  label: string = "Categoría de Producto";

  public data: CategoryVM[] = [];

  public itemDisabled(itemArgs: { dataItem: CategoryVM, index: number }) {
    return itemArgs.dataItem.UsesInventory;
  };
  
  public selectedItem: CategoryVM;

  disabled: boolean = true;

  public comboForm: FormGroup = new FormGroup({
    comboValue: new FormControl()
  });

  @Output() selectCategory: EventEmitter<CategoryVM> = new EventEmitter<CategoryVM>();

  @Output() usesInventory: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private dataService: CategoriesService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.dataService.getAll()
      .subscribe((response: CategoryVM[]) => {
        if (response) {
          //if (this.disableInventoryCategories) {
          //  this.data = response.filter(item => item.UsesInventory ==  false);
          //} else {
            this.data = response;
          //}
        }
        this.disabled = this.data.length <= 0;
      });
  }

  addEmpty() {
    let tempData = this.data;
    this.data = [];
    this.data.push({
      UUID: "",
      Name:"Todas las Categorías",
      Code: "",
      UsesInventory: false,
      DailyInventory: false
    });
    tempData.forEach(item => {
      this.data.push(item);
    });
  }

  isValidForm(): boolean {
    return this.comboForm.valid;
  }

  getItem(): CategoryVM {
    if (this.isValidForm()) {
      return this.selectedItem;
    }
    return null;
  }

  getItemId(): string {
    if (this.isValidForm()) {
      return this.selectedItem.UUID;
    }
    return "";
  }

  getItemName(): string {
    if (this.isValidForm()) {
      return this.selectedItem.Name;
    }
    return "";
  }

  selectionChange(value: CategoryVM) {
    this.selectedItem = value;
    this.selectCategory.emit(this.selectedItem);
  }

  setItemById(uuid: string) {
    if (this.data && this.data.length > 0 && uuid != null && uuid != "") {
      this.selectedItem = this.data.find(item => item.UUID === uuid);
      this.usesInventory.emit(this.selectedItem.UsesInventory);
      this.comboForm.setValue({
        comboValue: this.selectedItem
      });
    }
  }

  reset() {
    this.comboForm.reset();
    this.selectedItem = null;
  }

  


}
