
<div class="shift-starter">
  <header class="bg-primary text-white py-3">
    <div class="container">
      <h2>
        Bienvenido {{ username || '' }}
      </h2>
    </div>
  </header>

  <div class="content py-5">
    <div class="container">

      <div class="row row-cols-md-2 row-cols-sm-1">
        <!-- dinero en caja-->
        <div class="col">
          <h4 class="mb-2">Dinero en caja</h4>
          <form [formGroup]="cashForm"
                class="__entityForm">

            <kendo-floatinglabel text="Dinero en Caja">
              <input kendoTextBox name="Cash"
                     #cash
                     class="form-control"
                     type="number"
                     aria-label="Dinero en Caja"
                     placeholder="Dinero en Caja"
                     formControlName="Cash" />
            </kendo-floatinglabel>
            
          </form>
        </div>
        <!-- categorías de inventario diario -->
        <div class="col">
          <h4 class="mb-2">Inventario de Categorías</h4>
          <div class="__entityForm">

            <ng-container *ngFor="let item of dailyCategoriesInventory; let i = index">
              <kendo-floatinglabel text="{{ item.Category.Name }}">
                <input kendoTextBox name="{{item.Category.Name}}"
                       class="form-control"
                       type="number"
                       [(ngModel)]="item.Quantity"
                       [disabled]="!item.AllowEdition"
                       aria-label="Cantidad diaria de {{ item.Category.Name }} en kilogramos"
                       placeholder="Cantidad diaria de {{ item.Category.Name }} en kilogramos" />

                <div *ngIf="item.Quantity === null || item.Quantity < 0" class="alert alert-danger mt-1 mb-2">
                  <span *ngIf="item.Quantity === null">Por favor, ingrese una cantidad.</span>
                  <span *ngIf="item.Quantity < 0">La cantidad no puede ser menor a 0.</span>
                </div>
              </kendo-floatinglabel>
            </ng-container>

          </div>
        </div>
      </div>

      <div class="mt-5">
        <button class="btn btn-primary btn-block"
                type="submit"
                [disabled]="!isValidForm() || createButtonDisabled"
                (click)="onAccept()">
          Iniciar Turno
        </button>
      </div>
      
    </div>
  </div>

  <footer class="footer">
    <div class="container bg-dark text-white text-right">
      <button class="btn btn-outline-secondary"
              (click)="logout()">
        <i class="fa fa-logout"></i>
        Cerrar Sesión
      </button>
    </div>
  </footer>
</div>
