import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoryInventoryVM } from '../../../../models/view-models';
import { CategoryInventoryService } from '../../../../services/category-inventory.service';
import { LoadingService } from '../../service-pay/common/components/loading/loading.service';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { AuthenticationService } from '../../../../interceptors/authentication.service';

@Component({
  selector: 'app-category-inventory',
  templateUrl: './category-inventory.component.html',
  styleUrls: ['./category-inventory.component.scss']
})
export class CategoryInventoryComponent implements OnInit {

  data: CategoryInventoryVM[] = [];

  @ViewChild("grid", { read: GenericGridComponent, static: false })
  public grid: GenericGridComponent;

  userIsAdmin: boolean;

  showForm: boolean = false;

  constructor(private categoryInventoryService: CategoryInventoryService,
    private authService: AuthenticationService,
    private loading: LoadingService,) {
    this.userIsAdmin = this.authService.SessionUserValue().Rol === 'ADMIN';
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading.show();
    this.categoryInventoryService.getAll()
      .subscribe((response: CategoryInventoryVM[]) => {
        if (response) {
          this.data = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.data);
    this.grid.options.toolbarTitle = "Inventario de Categorías";
    this.grid.options.hideActionButtons = this.userIsAdmin;
    this.grid.options.showDetailsButton = false;
    this.grid.options.showDeleteButton = false;
    this.grid.options.showEditButton = this.userIsAdmin;
    this.grid.columns = [
      { field: "CategoryName", title: "Categoría" },
      { field: "Existence", title: "Existencia", numberType: true },
    ];
    this.grid.setGridOptions();
  }

  addNew = () => this.showForm = true;

  onSave(saveResult: boolean) {
    if (saveResult) {
      this.loadData();
    }
    this.showForm = false;
  }

}
