
<kendo-tabstrip>

  <kendo-tabstrip-tab [title]="'Productos'" [selected]="true">
    <ng-template kendoTabContent>
      <br />
      <inventory-products></inventory-products>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'CARDEX'" [selected]="false">
    <ng-template kendoTabContent>
      <br />
      <cardex></cardex>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Categorías'" [selected]="false">
    <ng-template kendoTabContent>
      <br />
      <app-category-inventory></app-category-inventory>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>
