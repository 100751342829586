
<h4>
  Reportes de Venta Por Rango de Fecha
</h4>

<hr />
<br />

<!-- date range selector -->
<div class="row">
  <div class="col-md-6 col-sm-12">
    <app-date-range-selector #dateRangeSelector></app-date-range-selector>
  </div>
  <div class="col-md-6 col-sm-12">
    <p style="padding-top: 25px;">
      <button class="btn btn-outline-primary"
              (click)="createReport()">
        Crear Reporte
      </button>
    </p>
  </div>
</div>

<br />
<hr />

<div [hidden]="!showReport">
  <app-generic-grid #reportGrid></app-generic-grid>
</div>

<div *ngIf="showReport">

  <!--<report-sale-item *ngFor="let sale of report.Sales"
                    [sale]="sale">
  </report-sale-item>-->

  <br />

  <report-total-by-item header="Total de Ventas por Método de Pago"
                        [items]="report.TotalByPaymentMethod">
  </report-total-by-item>

  <br />

  <report-total-by-item header="Total de Ventas por Categoría de Producto"
                        [items]="report.TotalByProductCategory">
  </report-total-by-item>

  <br />

  <div class="jumbotron text-center">
    <h2>
      TOTAL: {{ report.Total | currency }}
    </h2>
  </div>
</div>
