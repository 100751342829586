
<div [hidden]="showForm">

  <h3>
    Inventario de Categorías
  </h3>

  <ng-container *ngIf="userIsAdmin">
    <button class="btn btn-primary"
            [disabled]="data.length == 0"
            (click)="addNew()">
      Editar inventario por fecha <i class="fa fa-pencil"></i>
    </button>
  </ng-container>

  <hr />
  <br />

  <app-generic-grid #grid></app-generic-grid>

</div>

<ng-container *ngIf="showForm">
  <app-category-inventory-form
                               (onSave)="onSave($event)">
  </app-category-inventory-form>
</ng-container>
