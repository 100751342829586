import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { OperationResult } from '../models/view-models';
import { AlertifyService } from '../common/services/alertify.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LoadingService } from '../common/components/loading/loading.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private alertify: AlertifyService,
    private authService: AuthenticationService,
    private router: Router,
  private loading:LoadingService) { }

  intercept(request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          // client-side error
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {

            // server-side error
            let errorDetails: OperationResult<any> = error.error;
            
            //errorMessage = errorDetails.Details[0].Message;
            
            switch (error.status) {
              case 400: this.getBadRequestMessage(errorDetails);
                //this.showWarningMessage(errorMessage);
                break;
              case 401: this.authService.logout();
                break;
              case 403: this.router.navigate(['/home/access-denied']);
                this.showWarningMessage(`No tiene permisos para realizar esta acción`);
                break;
              case 404: this.showWarningMessage(`No hay resultados`);
                //this.showWarningMessage(errorMessage);
                break;
              case 406: this.showWarningMessage(`No aceptado`);
                //this.showWarningMessage(errorMessage);
                break;
              case 409: this.showWarningMessage("Registro duplicado");
                //this.showWarningMessage(errorMessage);
                break;
              default: this.showErrorMessage(`Error Code: ${error.status}`);
                //this.showWarningMessage(errorMessage);
                break;
            }

            this.loading.hide();
          }

          //console.log(error); //TODO: Remove for PROD
          return throwError(errorMessage);
        }));
  }

  showWarningMessage(msg: string) {
    this.alertify.warning(msg);
  }

  showErrorMessage(msg: string) {
    this.alertify.error(msg);
    //console.log(msg);
  }

  getBadRequestMessage(errorDetails: any): void {
    let msg = "Error en la petición";
    if (errorDetails.Message)
      msg = errorDetails.Message;

    this.showWarningMessage(msg)
  }
}
