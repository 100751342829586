import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-action-buttons',
  templateUrl: './header-action-buttons.component.html',
  styleUrls: ['./header-action-buttons.component.scss']
})
export class HeaderActionButtonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
