
<div class="col">
  <h4 class="mb-2">Inventario de Categorías</h4>
  <div class="__entityForm">

    <kendo-floatinglabel text="Seleccionar fecha">
      <kendo-datepicker format="dd/MM/yyyy"
                        [value]="insertionDate"
                        [min]="minDate"
                        [max]="maxDate"
                        (valueChange)="selectDate($event)">
      </kendo-datepicker>
    </kendo-floatinglabel>

    <ng-container *ngFor="let item of dailyCategoriesInventory; let i = index">
      <kendo-floatinglabel text="{{ item.Category.Name }}">
        <input kendoTextBox name="{{item.Category.Name}}"
               class="form-control" #categoryInventoryInput
               type="number"
               [(ngModel)]="item.Quantity"
               aria-label="Cantidad diaria de {{ item.Category.Name }} en kilogramos"
               placeholder="Cantidad diaria de {{ item.Category.Name }} en kilogramos" />
      </kendo-floatinglabel>
    </ng-container>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="onSubmit()">
        Guardar
      </button>
      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>

  </div>
</div>
