import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileInfo, FileRestrictions, SelectEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { environment } from '../../../../environments/environment';
import { CategorySelectorComponent } from '../../../common/components/category-selector/category-selector.component';
import { FilteredGridComponent } from '../../../common/components/filtered-grid/filtered-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { UnitSelectorComponent } from '../../../common/components/unit-selector/unit-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { ImportProductsResult, ProductVM } from '../../../models/view-models';
import { ProductsService } from '../../../services/products.service';
import { UploadService } from '../../../services/upload.service';
import { GenericGridComponent } from '../service-pay/common/components/generic-grid/generic-grid.component';
import { UniqueProductComponent } from './unique-product/unique-product.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products: ProductVM[] = [];
  product: ProductVM;
  entityForm: FormGroup;
  showForm: boolean = false;
  isEdition: boolean = false;
  showImportResults: boolean = false;

  @ViewChild("grid", { read: FilteredGridComponent, static: false })
  public grid: FilteredGridComponent;

  @ViewChild("code") code: ElementRef;
  @ViewChild("name") name: ElementRef;

  @ViewChild("categorySelector", { read: CategorySelectorComponent, static: false })
  public categorySelector: CategorySelectorComponent;

  @ViewChild("unitSelector", { read: UnitSelectorComponent, static: false })
  public unitSelector: UnitSelectorComponent;

  showInventoryDetails: boolean = false;
  profit: number = 0;

  showUpload: boolean = false;
  @ViewChild("csv", { read: UploadComponent, static: false })
  public csv: UploadComponent;
  myFiles: Array<FileInfo> = [];
  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv'],
    //maxFileSize: 10240,
    //minFileSize: 256,
  };
  fileSelected: boolean = false;
  filename: string = "";

  importResults: ImportProductsResult = {
    ImportedProducts: [],
    NonImportedProducts: [],
    NonConvertedProducts: []
  };
  @ViewChild("importedGrid", { read: GenericGridComponent, static: false })
  public importedGrid: GenericGridComponent;

  @ViewChild("nonConvertedGrid", { read: GenericGridComponent, static: false })
  public nonConvertedGrid: GenericGridComponent;

  @ViewChild("nonImportedGrid", { read: GenericGridComponent, static: false })
  public nonImportedGrid: GenericGridComponent;

  showImported: boolean = true;
  showNonImported: boolean = true;
  showNonConverted: boolean = true;

  downloadTemplateUrl: string = environment.CSVTemplate;

  showUniqueProductForm: boolean = false;
  @ViewChild("uniqueProduct", { read: UniqueProductComponent })
  public uniqueProduct: UniqueProductComponent;

  importedResultsCount: number = 0;
  nonImportedResultsCount: number = 0;
  nonConvertedResultsCount: number = 0;
  hideUsesInventory: boolean = false;

  constructor(private productsService: ProductsService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private uploadService: UploadService,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.initForm();
    this.loadData();
    this.onFormChanges();
  }

  initForm() {
    this.entityForm = this.fb.group({
      Code: ['', [Validators.required, Validators.minLength(1)]],
      Name: ['', [Validators.required, Validators.minLength(2)]],
      Description: [''],
      CostPrice: [0, [Validators.required]],
      RetailPrice: [0, [Validators.required]],
      WholesalePrice: [0, [Validators.required]],
      UsesInventory: [false],
      Quantity: [0],
      MinimumQuantity: [0],
      MaximumQuantity: [0],
      Group: [true],
    });
  }

  onFormChanges(): void {
    this.entityForm.valueChanges
      .subscribe((value) => {
        this.showInventoryDetails = value.UsesInventory;

        let p: number = ((value.RetailPrice - value.CostPrice) / value.RetailPrice ) * 100;
        let _pft: number = Math.round((p + Number.EPSILON) * 100) / 100;
        this.profit = _pft;

      }
      );
  }

  retailPriceFocusout() {
    if (!this.isEdition) {
      let rp = this.entityForm.controls['RetailPrice'].value;
      this.entityForm.controls['WholesalePrice'].setValue(rp);
    }
  }

  loadData() {
    this.loading.show();
    this.productsService.getAll()
      .subscribe((response: ProductVM[]) => {
        if (response) {
          this.products = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.products);
    this.grid.options.toolbarTitle = "Productos";
    this.grid.options.showDetailsButton = false;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
      { field: "Description", title: "Descripción" },
      { field: "CategoryName", title: "Categoría" },
      { field: "RetailPrice", title: "Precio", currencyType: true },
      { field: "WholesalePrice", title: "Precio Mayoreo", currencyType: true },
    ];
    this.grid.filterableColumns = [
      { field: "Code", operator: 'eq' },
      { field: "Name", operator: 'contains' },
      { field: "Description", operator: 'contains' },
    ];
    this.grid.setGridOptions();
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  setUniqueProductFormVisibility = () => this.showUniqueProductForm = !this.showUniqueProductForm;

  addNew() {
    this.isEdition = false;
    this.setFormVisibility();
    setTimeout(() => {
      this.code.nativeElement.focus();
      //this.code.nativeElement.value = "";
    }, 0);
  }

  addUniqueProduct() {
    this.setUniqueProductFormVisibility();
    this.uniqueProduct.isEdition = false;
    this.uniqueProduct.setFocusOnCodeInput();
  }

  isValidForm(): boolean {
    return this.entityForm.valid
      && this.categorySelector.isValidForm()
      && this.unitSelector.isValidForm();
  }

  save() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: ProductVM = Object.assign({
        CategoryId: this.categorySelector.getItemId(),
        CategoryName: this.categorySelector.getItemName(),
        UnitId: this.unitSelector.getItemId(),
        UnitName: this.unitSelector.getItemName(),
        Profit: this.profit,
      }, this.entityForm.value);
      this.productsService.insert(entity)
        .subscribe((response: ProductVM) => {
          if (response) {
            this.grid.addRow(response);
            this.cancel();
          }
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  cancel() {
    this.initForm();
    this.categorySelector.reset();
    this.unitSelector.reset();
    this.profit = 0;
    this.showInventoryDetails = false;
    this.setFormVisibility();
  }

  edit(value: ProductVM) {
    if (!value.IsUnique) {
      this.product = value;
      this.entityForm.setValue({
        Code: this.product.Code,
        Name: this.product.Name,
        Description: this.product.Description,
        CostPrice: this.product.CostPrice,
        RetailPrice: this.product.RetailPrice,
        WholesalePrice: this.product.WholesalePrice,
        UsesInventory: this.product.UsesInventory,
        Quantity: this.product.Quantity,
        MinimumQuantity: this.product.MinimumQuantity,
        MaximumQuantity: this.product.MaximumQuantity,
        Group: this.product.Group,
      });
      this.profit = this.product.Profit;
      this.categorySelector.setItemById(this.product.CategoryId);
      this.unitSelector.setItemById(this.product.UnitId);
      this.isEdition = true;
      this.setFormVisibility();
      setTimeout(() => {
        this.name.nativeElement.focus();
      }, 0);
    } else {
      this.uniqueProduct.edit(value);
      this.setUniqueProductFormVisibility();
    }
  }

  update() {
    if (this.isValidForm()) {
      this.loading.show();
      const entity: ProductVM = Object.assign({
        UUID: this.product.UUID,
        CategoryId: this.categorySelector.getItemId(),
        CategoryName: this.categorySelector.getItemName(),
        UnitId: this.unitSelector.getItemId(),
        UnitName: this.unitSelector.getItemName(),
        Profit: this.profit,
      }, this.entityForm.value);
      this.productsService.update(entity)
        .subscribe((response: ProductVM) => {
          if (response) {
            this.grid.updateRowByUUID(response);
            this.cancel();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
        });
    }
  }

  delete(value: ProductVM) {
    this.alertify.confirm(
      `Eliminar producto ${value.Name}`,
      () => {
        this.productsService.delete(value.UUID)
          .subscribe((response: boolean) => {
            if (response) {
              this.grid.deleteRow(value.UUID);
              this.loading.hide();
            }
          }, (err) => {
            this.loading.hide();
          });
      });
  }

  // Upload csv

  setImportResultsVisibility = () => this.showImportResults = !this.showImportResults;

  showUploadForm() {
    this.fileSelected = false;
    this.setImportResultsVisibility();
  }

  selectEventHandler(e: SelectEvent) {
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.myFiles = [];
        this.myFiles.push(file);
        this.filename = this.myFiles[0].name;
        this.fileSelected = true;
      }
    });
  }

  uploadFile() {
    this.loading.show();
    const formData: FormData = new FormData();
    formData.append('Name', this.myFiles[0].name);
    formData.append('File', this.myFiles[0].rawFile);
    //this.uploadService.uploadProducts(formData)
    this.uploadService.importProductsFromçPos(formData)
      .subscribe((response: ImportProductsResult) => {
        if (response) {
          this.importResults = response;
          this.setImportedGridOptions();
          this.setNonImportedGridOptions();
          this.setNonConvertedGridOptions();
          this.setImportResultsVisibility();
          this.loading.hide();
        }
      }, (err) => {
        this.loading.hide();
      });
      
  }

  cancelUpload() {
    this.myFiles = [];
    this.showUpload = false;
  }

  setImportedGridOptions() {
    this.importedGrid.setData(this.importResults.ImportedProducts);
    this.importedResultsCount = this.importResults.ImportedProducts.length;
    this.importedGrid.options.toolbarTitle = "Productos Importados";
    this.importedGrid.options.showToolbar = false;
    this.importedGrid.options.hideActionButtons = true;
    this.importedGrid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
      { field: "Description", title: "Descripción" },
      { field: "CategoryName", title: "Categoría" },
      { field: "RetailPrice", title: "Precio", currencyType: true },
    ];
    this.importedGrid.setGridOptions();
  }

  setNonImportedGridOptions() {
    this.nonImportedGrid.setData(this.importResults.NonImportedProducts);
    this.nonImportedResultsCount = this.importResults.NonImportedProducts.length;
    this.nonImportedGrid.options.toolbarTitle = "Productos NO Importados";
    this.nonImportedGrid.options.showToolbar = false;
    this.nonImportedGrid.options.hideActionButtons = true;
    this.nonImportedGrid.columns = [
      { field: "Codigo_de_Producto", title: "Código" },
      { field: "Nombre_de_Producto", title: "Nombre" },
      { field: "Mensaje_de_Error", title: "Error" },
    ];
    this.nonImportedGrid.setGridOptions();
  }

  setNonConvertedGridOptions() {
    this.nonConvertedGrid.setData(this.importResults.NonConvertedProducts);
    this.nonConvertedResultsCount = this.importResults.NonConvertedProducts.length;
    this.nonConvertedGrid.options.toolbarTitle = "Errores de conversión";
    //this.nonImportedGrid.options.showToolbar = false;
    this.nonConvertedGrid.options.hideActionButtons = true;
    this.nonConvertedGrid.columns = [
      { field: "Codigo", title: "Codigo" },
      { field: "Codigo", title: "Codigo" },
      { field: "Producto", title: "Producto" },
      { field: "Costo", title: "Costo" },
      { field: "Venta", title: "Venta" },
      { field: "Mayoreo", title: "Mayoreo" },
      { field: "Departamento", title: "Departamento" },
      { field: "Existencia", title: "Existencia" },
      { field: "Minimo", title: "Minimo" },
      { field: "Maximo", title: "Maximo" },
      { field: "Tipo", title: "Tipo" },
    ];
    this.nonConvertedGrid.setGridOptions();
  }

  closeResults() {
    this.setImportResultsVisibility();
    this.showUpload = false;
    this.loadData();
  }

  uniqueProductSave(value: ProductVM) {
    this.loading.show();
    this.productsService.insert(value)
      .subscribe((response: ProductVM) => {
        if (response) {
          this.grid.addRow(response);
          this.uniqueProduct.cancel();
          this.showUniqueProductForm = false;
          this.loading.hide();
        }
      }, (err) => {
        this.loading.hide();
      });
  }

  uniqueProductUpdate(value: ProductVM) {
    this.loading.show();
    this.productsService.update(value)
      .subscribe((response: ProductVM) => {
        if (response) {
          this.grid.updateRowByUUID(response);
          this.uniqueProduct.cancel();
          this.showUniqueProductForm = false;
          this.loading.hide();
        }
      }, (err) => {
        this.loading.hide();
      });
  }

  uniqueProductCancel() {
    this.showUniqueProductForm = false;
  }

  categoryUsesInventory = (value: boolean) => this.hideUsesInventory = value;


}
