<!-- grid -->
<div [hidden]="showMulti">

  <h4>
    Cortes de Turno Múltiple
  </h4>

  <button class="btn btn-primary"
          [disabled]="!userIsAdmin"
          (click)="openShiftSelector()">
    Crear corte múltiple
  </button>

  <hr />
  <br />

  <app-generic-grid #grid
                    (onCellClicked)="select($event)">
  </app-generic-grid>

  <app-closed-shifts-selector-dialog #shiftsSelector
                                     (onShiftsSelected)="createFromMultipleShifts($event)">
  </app-closed-shifts-selector-dialog>

</div>

<div [hidden]="!showMulti">

  <div class="row">
    <div class="col-md-6 col-sm-12"
         style="padding-top: 10px;">
      <h2>Corte de Múltiples Turnos</h2>
    </div>
    <div class="col-md-6 col-sm-12">
      <kendo-floatinglabel text="Mostrando detalles de">
        <kendo-dropdownlist #combo
                            [data]="shiftCashCloses"
                            [textField]="'CreateDate'"
                            [valueField]="'ShiftId'"
                            (selectionChange)="onShiftSelectionChange($event)">
        </kendo-dropdownlist>
      </kendo-floatinglabel>
    </div>
  </div>

  <hr />
  <br />

  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">
      <div class="d-flex justify-content-end __formButtonsContainer"
           *ngIf="isGeneral">
        <button class="btn btn-outline-primary"
                *ngIf="showPrintButton"
                (click)="print()">
          Imprimir
        </button>
        <button class="btn btn-primary"
                *ngIf="!showDetails"
                type="submit"
                (click)="save()">
          Guardar
        </button>
        <button class="btn btn-secondary"
                *ngIf="!showDetails"
                type="button"
                (click)="cancel()">
          Cancelar
        </button>
        <button class="btn btn-secondary"
                *ngIf="showDetails"
                type="button"
                (click)="cancel()">
          Cerrar
        </button>
      </div>
    </div>
  </div>

  <app-cash-close #multiCashCloseDetails></app-cash-close>

  <div class="row">
    <div class="col-md-8 col-sm-12 mx-auto">
      <div class="d-flex justify-content-end __formButtonsContainer"
           *ngIf="isGeneral">
        <button class="btn btn-primary"
                *ngIf="!showDetails"
                type="submit"
                (click)="save()">
          Guardar
        </button>
        <button class="btn btn-secondary"
                *ngIf="!showDetails"
                type="button"
                (click)="cancel()">
          Cancelar
        </button>
        <button class="btn btn-secondary"
                *ngIf="showDetails"
                type="button"
                (click)="cancel()">
          Cerrar
        </button>
      </div>
    </div>
  </div>

</div>
