import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { CategoryVM, DailyCategoryInventoryDTO, OperationResult } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  apiUrl: string = `${environment.apiUrl}/Category`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  insert(category: CategoryVM) {
    return this.http.post(this.apiUrl, category)
      .pipe(map((response: OperationResult<CategoryVM>) => {
        if (response.Success) {
          this.alertify.success("Se agregó la categoría");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(category: CategoryVM) {
    return this.http.put(this.apiUrl, category)
      .pipe(map((response: OperationResult<CategoryVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó la categoría");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó la categoría");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<CategoryVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<CategoryVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getDailyInventoryCategories(dailyCategoriesOnly: boolean) {
    const uri = `${this.apiUrl}/GetDailyInventoryCategories`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<DailyCategoryInventoryDTO[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getDailyInventoryCategoriesFromDate(date: Date) {
    const uri = `${this.apiUrl}/GetDailyInventoryCategoriesFromDate?date=${date.toISOString()}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<DailyCategoryInventoryDTO[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
}
