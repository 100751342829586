
<ng-container *ngIf="loading">
  <div class="text-center">
    <i class="fa fa-spinner fa-spin fa-2x"></i>
    &nbsp;
    <b>Cargando</b>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <div class="d-none d-md-block">
    <div class="__no-tabs d-flex justify-content-center align-items-center"
         *ngIf="tickets.length === 0">
      <b>No hay tickets guardados</b>
      <button class="btn btn-outline-secondary"
              (click)="loadSavedTickets()"
              aria-label="Actualizar tickets">
        <i class="fa fa-refresh"></i> Actualizar
      </button>
    </div>

    <div class="__tabs" *ngIf="tickets.length > 0">
      <div class="__tab text-center mt-2">
        <button class="btn btn-outline-secondary"
                (click)="loadSavedTickets()"
                aria-label="Actualizar tickets">
          <i class="fa fa-refresh"></i> Actualizar
        </button>
      </div>
      <div class="__tab"
           *ngFor="let item of tickets"
           (click)="selectTicket(item)">
        {{ item.TicketId }}
        <br />
        {{ item.Date | date: 'dd/MM/yyyy HH:mm:ss' }}
        <br />
        {{ item.Total | currency }}
      </div>
    </div>
  </div>
  <div class="d-sm-none">
    <div class="collapse-container"
         [ngClass]="{'collapse': collapse }">
      <div class="__no-tabs d-flex justify-content-start align-items-center"
           *ngIf="tickets.length === 0">
        <b>No hay tickets guardados</b>
        <button class="btn btn-outline-secondary"
                (click)="loadSavedTickets()"
                aria-label="Actualizar tickets">
          <i class="fa fa-refresh"></i> Actualizar
        </button>
      </div>

      <div class="__tabs" *ngIf="tickets">
        <div class="__tab text-center mt-2">
          <button class="btn btn-outline-secondary"
                  (click)="loadSavedTickets()"
                  aria-label="Actualizar tickets">
            <i class="fa fa-refresh"></i> Actualizar
          </button>
        </div>
        <div class="__tab"
             *ngFor="let item of tickets"
             (click)="selectTicket(item)">
          {{ item.TicketId }}
          <br />
          {{ item.Date | date: 'dd/MM/yyyy' }}
          <br />
          {{ item.Date | date: 'HH:mm:ss' }}
          <br />
          {{ item.Total | currency }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
