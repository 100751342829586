
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a routerLink="/home/pos/sales"
     class="navbar-brand">
    {{ appName }}
  </a>
  <button class="navbar-toggler"
          type="button"
          (click)="collapse =!collapse "
          aria-label="Menú">
    <i class="fa fa-bars fa-2x text-muted" aria-hidden="true"></i>
  </button>

  <div class="navbar-collapse"
       [ngClass]="{'collapse ': collapse }">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item"
          *ngFor="let item of menuItems">
        <a class="nav-link"
           routerLink="{{ item.url }}"
           (click)="menuItemClick(item)"
           [routerLinkActive]="['router-link-active']">
          {{ item.title }}
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <app-user-info [username]="username"></app-user-info>
      </li>
      <li class="nav-item"
          *ngIf="role === 'ADMIN'">
        <a class="nav-link"
           routerLink="/settings/general-settings"
           (click)="collapse = true"
           [routerLinkActive]="['router-link-active']">
          Configuración <i class="fa fa-gears"></i>
        </a>
      </li>
    </ul>

  </div>
</nav>

<hr />


