import { Component, OnInit } from '@angular/core';
import { SettingsItem } from '../../../models/ComponentModels';
import { BUILD_DATE } from '../../../../environments/build-date';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  buildDate = BUILD_DATE;

  items: SettingsItem[] = [
    {
      Title: 'Categorías de Productos',
      Url: '/settings/categories',
      Icon:'fa-shopping-basket'
    },
    {
      Title: 'Unidades de Medida',
      Url: '/settings/units',
      Icon: 'fa-tags'
    },
    {
      Title: 'Métodos de Pago',
      Url: '/settings/payment-methods',
      Icon: 'fa-credit-card-alt'
    },
    {
      Title: 'Usuarios',
      Url: '/settings/users',
      Icon: 'fa-users'
    },
    {
      Title: 'Permisos',
      Url: '/settings/rol-permissions',
      Icon: 'fa-unlock-alt'
    },
    {
      Title: 'Turnos',
      Url: '/settings/shifts',
      Icon: 'fa-hourglass-o'
    },
    {
      Title: 'Productos',
      Url: '/settings/products-settings',
      Icon: 'fa-shopping-cart'
    },
    //{
    //  Title: 'Credenciales',
    //  Url: '/settings/external-credentials',
    //  Icon: 'fa-key'
    //},
    {
      Title: 'Monedas',
      Url: '/settings/currencies',
      Icon: 'fa-dollar'
    },
    {
      Title: 'Logs',
      Url: '/settings/app-logs',
      Icon: 'fa-key'
    },
    {
      Title: 'Eliminar registros operativos',
      Url: '/settings/remove-records',
      Icon: 'fa-trash'
    },
    {
      Title: `build: ${this.buildDate.split('T')[0]}`,
      Url: '/settings/general-settings',
      Icon: 'fa-square-o'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
