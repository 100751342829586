import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { ShiftVM, OperationResult, ShiftStarterDto } from '../models/view-models';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { LocalData } from '../models/LocalData';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  apiUrl: string = `${environment.apiUrl}/Shift`;

  constructor(private http: HttpClient,
    private error: ErrorService,
    private alertify: AlertifyService) { }

  insert(shift: ShiftVM) {
    return this.http.post(this.apiUrl, shift)
      .pipe(map((response: OperationResult<ShiftVM>) => {
        if (response.Success) {
          this.alertify.success("Se creó el turno");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  update(shift: ShiftVM) {
    return this.http.put(this.apiUrl, shift)
      .pipe(map((response: OperationResult<ShiftVM>) => {
        if (response.Success) {
          this.alertify.success("Se editó el turno");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }
  
  closeActiveShift(shift: ShiftVM) {
    const uri = `${this.apiUrl}/closeActiveShift`;
    return this.http.put(uri, shift)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se cerró el turno activo");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  openShift(shift: ShiftVM) {
    const uri = `${this.apiUrl}/OpenShift`;
    return this.http.put(uri, shift)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se abrió el turno");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  delete(uuid: string) {
    const uri = `${this.apiUrl}?uuid=${uuid}`;
    return this.http.delete(uri)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se eliminó el turno");
          return response.Data;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  startShift(shiftStarter: ShiftStarterDto) {
    const uri = `${this.apiUrl}/StartShift`;
    return this.http.post(uri, shiftStarter)
      .pipe(map((response: OperationResult<ShiftVM>) => {
        if (response.Success) {
          this.alertify.success("Se creó el turno");
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((response: OperationResult<ShiftVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getById(uuid: string) {
    const uri = `${this.apiUrl}/GetByIdentifier?uuid=${uuid}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ShiftVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getByUserId(userId: string) {
    const uri = `${this.apiUrl}/GetByUserId?userId=${userId}`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ShiftVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  getActiveShift() {
    const uri = `${this.apiUrl}/GetActiveShift`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ShiftVM>) => {
        if (response.Success) {
          return response.Data;
        } else {
          //this.error.logError(response);
          return null;
        }
      }));
  }
  
  getActiveShifts() {
    const uri = `${this.apiUrl}/GetActiveShifts`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ShiftVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          //this.error.logError(response);
          return null;
        }
      }));
  }

  getClosedShifts() {
    const uri = `${this.apiUrl}/GetClosedShifts`;
    return this.http.get(uri)
      .pipe(map((response: OperationResult<ShiftVM[]>) => {
        if (response.Success) {
          return response.Data;
        } else {
          //this.error.logError(response);
          return null;
        }
      }));
  }

  validateActiveShift(today: Date): boolean {
    let activeShift: ShiftVM = JSON.parse(localStorage.getItem(LocalData.ACTIVE_SHIFT));
    let shiftDate: Date = new Date(activeShift.CreateDate);

    if (today.getMilliseconds() < this.setEndDate(shiftDate).getMilliseconds()) {
      return false;
    }
    return true;
  }

  setStartDate(dt: Date): Date {
    let result: Date = new Date(dt);
    result.setHours(0, 0, 0, 0);
    return result;
  }

  setEndDate(dt: Date): Date {
    let result: Date = new Date(dt);
    result.setHours(23, 59, 59);
    return result;
  }

  activeShiftExists(): boolean {
    if (localStorage.getItem(LocalData.ACTIVE_SHIFT) !== null) 
      return true;
    return false;
  }

  getLocalActiveShift(): ShiftVM {
    let activeShift: ShiftVM = JSON.parse(localStorage.getItem(LocalData.ACTIVE_SHIFT));
    return activeShift;
  }

  getActiveShiftId(): string {
    return this.getLocalActiveShift().UUID;
  }

  getActiveShiftUserId(): string {
    return this.getLocalActiveShift().UserId;
  }

  getActiveShiftInfo(): ShiftVM {
    return this.getLocalActiveShift();
  }

}
