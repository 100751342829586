<!-- grid -->
<div [hidden]="showForm || showImportResults || showUniqueProductForm">

  <h3>
    Productos
  </h3>

  <br />

  <br />

  <div class="btn-group">
    <button class="btn btn-primary"
            (click)="addNew()">
      Agregar <i class="fa fa-plus"></i>
    </button>
    <button class="btn btn-outline-primary"
            (click)="showUpload=!showUpload">
      Importar desde Archivo <i class="fa fa-file"></i>
    </button>
    <button class="btn btn-primary"
            (click)="addUniqueProduct()">
      Agregar Producto Único
    </button>
  </div>

  <!-- upload collapse -->
  <div [ngClass]="{'collapse': !showUpload}">
    <div class="row">
      <div class="col-md-6 col-sm-12 mx-auto">
        <br /><br />
        <p>
          <a href="{{ downloadTemplateUrl }}">
            Descargar Plantilla <i class="fa fa-file-excel-o"></i>
          </a>
        </p>
        <br /><br />

        <div [hidden]="fileSelected">
          <kendo-upload #csv
                        [restrictions]="fileRestrictions"
                        [multiple]="false"
                        [autoUpload]="false"
                        (select)="selectEventHandler($event)">
            <kendo-upload-messages [select]="'Seleccionar archivo'"
                                   [invalidFileExtension]="'Tipo de archivo inválido'"
                                   [dropFilesHere]="'Arrastrar archivo'">
            </kendo-upload-messages>
          </kendo-upload>
          <div [hidden]="fileSelected">
            <br /><br />
            <button class="btn btn-outline-secondary btn-block"
                    (click)="cancelUpload()">
              Cancelar
            </button>
          </div>
        </div>

        <div [hidden]="!fileSelected">
          <p>
            {{ filename }}
          </p>
          <div class="btn-group text-right">
            <button class="btn btn-primary"
                    (click)="uploadFile()">
              Importar
            </button>
            <button class="btn btn-secondary"
                    (click)="cancelUpload">
              Cancelar
            </button>
          </div>
        </div>

        <br /><br />
      </div>
    </div>
  </div>

  <hr />
  <br />

  <div [hidden]="false">
    <app-filtered-grid #grid
                       (rowToEdit)="edit($event)"
                       (rowToDelete)="delete($event)">
    </app-filtered-grid>
  </div>

</div>

<!-- form -->
<div [hidden]="!showForm">

  <form [formGroup]="entityForm"
        class="__entityForm">

    <h4 *ngIf="isEdition">
      Editar Producto
    </h4>

    <h4 *ngIf="!isEdition">
      Agregar Producto
    </h4>


    <kendo-floatinglabel text="Código">
      <input kendoTextBox name="Code"
             #code
             class="form-control"
             [readOnly]="isEdition"
             maxlength="50"
             [ngClass]="{'is-invalid': entityForm.get('Code').errors && entityForm.get('Code').touched}"
             type="text"
             aria-label="Código"
             placeholder="Código"
             formControlName="Code" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Nombre">
      <input kendoTextBox name="Name"
             #name
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('Name').errors && entityForm.get('Name').touched}"
             type="text"
             aria-label="Nombre"
             placeholder="Nombre"
             formControlName="Name" />
    </kendo-floatinglabel>

    <app-category-selector #categorySelector
                           [disableInventoryCategories]="true"
                           (usesInventory)="categoryUsesInventory($event)">
    </app-category-selector>

    <app-unit-selector #unitSelector></app-unit-selector>

    <kendo-floatinglabel text="Precio de Costo">
      <input kendoTextBox name="CostPrice"
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('CostPrice').errors && entityForm.get('CostPrice').touched}"
             type="number"
             aria-label="Precio de Costo"
             placeholder="Precio de Costo"
             formControlName="CostPrice" />
    </kendo-floatinglabel>

    <kendo-floatinglabel text="Precio de Menudeo">
      <input kendoTextBox name="RetailPrice"
             class="form-control"
             (focusout)="retailPriceFocusout()"
             [ngClass]="{'is-invalid': entityForm.get('RetailPrice').errors && entityForm.get('RetailPrice').touched}"
             type="number"
             aria-label="Precio de Menudeo"
             placeholder="Precio de Menudeo"
             formControlName="RetailPrice" />
    </kendo-floatinglabel>

    <p class="form-control d-flex justify-content-between align-items-center">
      <b>Ganancia</b>
      <span>
        {{profit}} %
      </span>
    </p>

    <kendo-floatinglabel text="Precio de Mayoreo">
      <input kendoTextBox name="WholesalePrice"
             class="form-control"
             [ngClass]="{'is-invalid': entityForm.get('WholesalePrice').errors && entityForm.get('WholesalePrice').touched}"
             type="number"
             aria-label="Precio de Mayoreo"
             placeholder="Precio de Mayoreo"
             formControlName="WholesalePrice" />
    </kendo-floatinglabel>

    <label class="form-check-label">
      Agrupar
      <input type="checkbox" kendoCheckBox
             formControlName="Group" />
    </label>

    <label class="form-check-label" [hidden]="hideUsesInventory">
      Utiliza Inventario
      <input type="checkbox" kendoCheckBox
             formControlName="UsesInventory" />
    </label>

    <div [ngClass]="{'collapse': !showInventoryDetails}">

      <kendo-floatinglabel text="Existencia">
        <input kendoTextBox name="Quantity"
               class="form-control"
               type="number"
               aria-label="Existencia"
               placeholder="Existencia"
               formControlName="Quantity" />
      </kendo-floatinglabel>

      <div class="row">
        <div class="col-6">
          <kendo-floatinglabel text="Cantidad Mínima">
            <input kendoTextBox name="MinimumQuantity"
                   class="form-control"
                   type="number"
                   aria-label="Cantidad Mínima"
                   placeholder="Cantidad Mínima"
                   formControlName="MinimumQuantity" />
          </kendo-floatinglabel>
        </div>
        <div class="col-6">
          <kendo-floatinglabel text="Cantidad Máxima">
            <input kendoTextBox name="MaximumQuantity"
                   class="form-control"
                   type="number"
                   aria-label="Cantidad Máxima"
                   placeholder="Cantidad Máxima"
                   formControlName="MaximumQuantity" />
          </kendo-floatinglabel>
        </div>
      </div>

    </div>

    <kendo-floatinglabel text="Descripción">
      <input kendoTextBox name="Description"
             class="form-control"
             type="text"
             aria-label="Descripción"
             placeholder="Descripción"
             formControlName="Description" />
    </kendo-floatinglabel>

    <div class="d-flex justify-content-end __formButtonsContainer">
      <button class="btn btn-primary"
              *ngIf="!isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="save()">
        Guardar
      </button>
      <button class="btn btn-primary"
              *ngIf="isEdition"
              type="submit"
              [disabled]="!isValidForm()"
              (click)="update()">
        Guardar cambios
      </button>

      <button class="btn btn-secondary"
              type="button"
              (click)="cancel()">
        Cancelar
      </button>
    </div>

  </form>

</div>

<!-- import results -->
<div [hidden]="!showImportResults">
  <div class="row">
    <div class="col-6">
      <h3>
        Resultados de la Importación
      </h3>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-outline-secondary"
              (click)="closeResults()">
        Cerrar
      </button>
    </div>
  </div>

  <hr />
  <br />

  <h4 style="cursor:pointer"
      (click)="showImported=!showImported">
    Productos Importados: {{ importedResultsCount }}
  </h4>

  <div [ngClass]="{'collapse': !showImported}">
    <app-generic-grid #importedGrid></app-generic-grid>
  </div>

  <hr />
  <br />
  <br />

  <h4 style="cursor:pointer"
      (click)="showNonConverted=!showNonConverted">
    Errores al convertir: {{ nonConvertedResultsCount }}
  </h4>

  <div [ngClass]="{'collapse': !showNonConverted}">
    <app-generic-grid #nonConvertedGrid></app-generic-grid>
  </div>


  <hr />
  <br />
  <br />

  <h4 style="cursor:pointer"
      (click)="showNonImported=!showNonImported">
    Productos NO Importados: {{ nonImportedResultsCount }}
  </h4>

  <div [ngClass]="{'collapse': !showNonImported}">
    <app-generic-grid #nonImportedGrid></app-generic-grid>
  </div>

</div>


<div [hidden]="!showUniqueProductForm">
  <app-unique-product #uniqueProduct
                      (onFormSave)="uniqueProductSave($event)"
                      (onFormUpdate)="uniqueProductUpdate($event)"
                      (onFormCancel)="uniqueProductCancel()">
  </app-unique-product>
</div>
