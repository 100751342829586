import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../interceptors/error.service';
import { AlertifyService } from '../common/services/alertify.service';
import { CategoryInventoryVM, OperationResult, SetDailyInventoryCategoriesDTO } from '../models/view-models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryInventoryService {

  apiUrl: string = `${environment.apiUrl}/CategoryInventory`;

  constructor(private http: HttpClient,
    private logError: ErrorService,
    private alertify: AlertifyService) { }

  InsertDateInventory(data: SetDailyInventoryCategoriesDTO) {
    const uri = `${this.apiUrl}/InsertDateInventory`;
    return this.http.post<OperationResult<boolean>>(uri, data)
      .pipe(map((response:OperationResult<boolean>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return false;
        }
      }));
  }
  
  updateInventoryFromDate(data: SetDailyInventoryCategoriesDTO) {
    const uri = `${this.apiUrl}/UpdateInventoryFromDate`;
    
    return this.http.put<OperationResult<boolean>>(uri, data)
      .pipe(map((response:OperationResult<boolean>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return false;
        }
      }));
  }

  getAll() {
    return this.http.get(this.apiUrl)
      .pipe(map((_res: any) => {
        const response = _res as OperationResult<CategoryInventoryVM[]>;
        if (response.Success) {
          return response.Data;
        } else {
          this.logError.logError(response);
          return null;
        }
      }));
  }

}
