
<h4>
  Reportes de CARDEX
</h4>

<hr />
<br />

<!-- date range selector -->
<div class="row">
  <div class="col-md-6 col-sm-12">
    <app-date-range-selector #dateRangeSelector></app-date-range-selector>
  </div>
  <div class="col-md-6 col-sm-12">
    <p style="padding-top: 25px;">
      <button class="btn btn-outline-primary"
              (click)="createReport()">
        Crear Reporte
      </button>
    </p>
  </div>
</div>

<br />
<hr />

<div [hidden]="!showReport">
  <app-generic-grid #reportGrid></app-generic-grid>
</div>
