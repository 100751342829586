import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit {

  @Output() selectDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Input("value") value: Date = new Date();
  @Input("min") min: Date = new Date(2021, 0, 1);
  @Input("max") max: Date = new Date();
  @Input() showPreviousNextButtons: boolean = true;
  @Input("readOnly") readOnly: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  public onChange(dt: Date) {
    this.selectDate.emit(dt);
  }

  prev() {
    this.value = new Date(
      this.value.setDate(
        this.value.getDate() - 1)
    );
    this.selectDate.emit(this.value);
  }

  next() {
    this.value = new Date(
      this.value.setDate(
        this.value.getDate() + 1)
    );
    this.selectDate.emit(this.value);
  }



}
