
<ng-container *ngIf="hideContent">
  <app-shift-starter #shiftStarter
                     (onUserIsAdmin)="userIsAdmin($event)"
                     (onDialogChange)="dialogChange($event)">
  </app-shift-starter>
</ng-container>

<app-shift-selector-dialog #shiftSelectorDialog
                           (onShiftSelect)="onShiftSelect($event)">
</app-shift-selector-dialog>

<ng-container *ngIf="!hideContent">
  <router-outlet></router-outlet>
</ng-container>
