
<div class="d-flex justify-content-between align-items-center">
  <h3>Logs</h3>
  <app-date-selector (selectDate)="onDateChange($event)"></app-date-selector>
</div>

<hr />
<br />

<div class="table-responsive" [hidden]="showDetails">
  <table class="table table-striped">
    <thead>
    <tr>
    <th class="align-items-center">
      ID
    </th>
    <th class="align-items-center">
      Fecha
    </th>
    <th class="align-items-center">
      Nivel
    </th>
    <th class="align-items-center">
      Logger
    </th>
    <th class="align-items-center">
      Mensaje
    </th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of logs">
        <td>
          {{ log.Id }}
        </td>
        <td>
          {{ log.Date | date: 'yyy-MM-dd H:mm:ss' }}
        </td>
        <td>
          {{ log.Level }}
        </td>
        <td>
          {{ log.Logger }}
        </td>
        <td>
          {{ log.Message }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="showDetails">
  <div class="d-flex justify-content-between align-items-center">
    <b>Detalles: {{log.Message}}</b>
    <button class="btn btn-secondary"
            (click)="close()">
      Cerrar
    </button>
  </div>
  <hr />
  <div class="list-group">
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Date</b>
      <span class="badge">{{ log.Date | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Thread</b>
      <span class="badge">{{ log.Thread }}</span>
    </div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <b>Level</b>
      <span class="badge">{{ log.Level }}</span>
    </div>
    <div class="list-group-item">
      <b>Logger</b>
      <br />
      <pre>{{ log.Logger }}</pre>
    </div>
    <div class="list-group-item">
      <b>Stacktrace</b>
      <br />
      <pre>{{ log.Stacktrace }}</pre>
    </div>
    <div class="list-group-item">
      <b>Logger</b>
      <br />
      <pre>{{ log.Exception }}</pre>
    </div>
  </div>
</div>
